<template>
  <!-- 加载中 -->
  <div class="main" v-if="isLoadingShow">
    <Loading/>
  </div>

  <div class="product common-padding">
    <router-link to="/mobileCart">
    <div class="cart">

        <div>
          <div class="cart-count">{{ bagCount }}</div>
          <img src="~@/assets/img/bag.png">
        </div>

    </div>
    </router-link>
    <h4 class="title">{{ title }}</h4>
    <div class="product-no">
      <span>No: {{ no }}</span>
    </div>
    <a :href="pdf">
      <div class="pdf">
        <img src="~@/assets/img/download.png">Download Product Instructions
      </div>
    </a>
    <div class="product-img border-common">
      <img :src="image">
    </div>
    <div class="product-intro"  v-html="processedDescription">
    </div>
  </div>
  <div class="pro-component">
    <div class="common-padding">
      <h4 class="title">Component</h4>
      <div class="component-item" v-for="item in components" :key="item.id">
        <div class="stock" v-if="item.stock===0">Unavailable</div>
        <div class="component-item-img">
          <img :src="item.image">
        </div>
        <div class="component-item-txt">
          <p><span>Component name:</span> <span>{{ item.name }}</span></p>
          <p><span>Component Code:</span> <span>{{ item.code }}</span></p>
          <p><span>Part NO.：</span> <span>{{ item.part_no }}</span></p>
          <p class="other-p">Description:</p>
          <p>{{ item.description}}</p>
        </div>
        <div class="component-bottom">
          <div class="component-count">
            <span @click="decreaseComponentCount(item.id)" :class="{ 'disabled': item.disabled || item.stock === 0}">-</span>
            <input class="c-count" type="text" :value="item.count" readonly>
            <span @click="increaseComponentCount(item.id)" :class="{ 'disabled': item.disabled || item.count === item.stock }">+</span>
          </div>
          <div class="add-cart" @click="addToCart(item.id)" :class="{ 'disabled': item.stock === 0 }">
            Add to cart
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--提示窗-->
  <div v-if="isPopupVisible" class="popup">
    {{ popupText }}
  </div>

</template>

<script>
import {getMobileDetails} from "@/network/main";
import Loading from "@/components/loading";
export default {
  name: "replacement",
  components: {
    Loading
  },
  data() {
    return{
      code: null,
      title:'',
      image:'',
      pdf:'',
      content:"",
      no:'',
      components:[],
      bagCount:0,
      selectedMobile: null,
      isPopupVisible: false,
      popupText: '',
      isLoadingShow:true,
    }
  },
  mounted() {
    this.code = this.$route.params.code;
    console.log('路由链接code---',this.code);
    // 将code保存在localStorage中
    localStorage.setItem('code', this.code);

    this.getMobileDetails();
    // 检查缓存中的selectedLength
    const selectedLength = localStorage.getItem('selectedLength');
    if (selectedLength) {
      this.bagCount = parseInt(selectedLength);
    }
  },
  methods: {
    //产品详情
    getMobileDetails() {
      getMobileDetails(this.code).then(res =>{
        if (res.data.code === 200) {
          this.isLoadingShow=false;
          this.title = res.data.data.title;
          this.image = res.data.data.image;
          this.pdf = res.data.data.pdf;
          this.content = res.data.data.content;
          this.no = res.data.data.no;
          this.components = res.data.data.components.map(component => ({
            ...component,
            count: 0
          }));
        }
      })
    },
    //-+
    decreaseComponentCount(id) {
      const component = this.components.find(item => item.id === id);
      if (component && component.count > 0 && component.stock > 0) {
        component.count--;
        if (component.count === 0) {
          component.disabled = true;
        }
      }
    },
    increaseComponentCount(id) {
      const component = this.components.find(item => item.id === id);
      if (component && component.count < component.stock) {
        component.count++;
        if (component.count === 1) {
          component.disabled = false;
        }
      }
    },
    addToCart(id) {
      const component = this.components.find(item => item.id === id);
      if (component && component.count > 0 && component.stock > 0) {
        let selectedMobile = JSON.parse(localStorage.getItem('selectedMobile')) || {};
        selectedMobile[id] = component.count;
        localStorage.setItem('selectedMobile', JSON.stringify(selectedMobile));
        // 计算总数量
        let selectedLength = Object.keys(selectedMobile).length;
        localStorage.setItem('selectedLength', selectedLength);

        console.log(selectedMobile);
        console.log(selectedLength);
        this.bagCount = selectedLength;
        //提示
        this.isPopupVisible = true;
        this.popupText = "Added to cart";
        setTimeout(() => {
          this.isPopupVisible = false;
        }, 1500);
      }
    },
  },
  computed: {
    processedDescription() {
      if (this.content) {
        return this.content.replace(/\r?\n/g, "<br>");
      }
      return ""; // 如果content为空，则返回一个空字符串
    },
  },

}
</script>

<style scoped>
 @import "~@/assets/css/mobile.css";
</style>